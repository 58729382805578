import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route
} from "react-router-dom";
import "./App.css";
import { UserLoggedInProvider } from "DataStore/UserLoggedIn";
import { CodesProvider } from "DataStore/Codes";
import Login from "pages/Login";
import Dashboard from "pages/Dashboard";

function App() {
  return (
    <Router>
      <UserLoggedInProvider>
        <Switch>
          <Route path="/dashboard" render={() => <CodesProvider><Dashboard /></CodesProvider>} />
          <Route path="/login" render={() => <Login />} />
          <Redirect from="/" to="/login" />
        </Switch>
      </UserLoggedInProvider>
    </Router>
  );
}

export default App;
