import React from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import withRoot from "../../theme/withRoot";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import { UserLoggedInContext } from "DataStore/UserLoggedIn";
import { TextField } from "@material-ui/core";
import SimpleSnackbar from "components/Snackbar";
import { t } from "DataStore/langues";
import logo from "assets/images/logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    backgroundColor: theme.palette.primary.dark
  },
  card: {
    maxWidth: "100%"
  },
  media: {
    height: 400
  }
}));

const Login = props => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [response, setResponse] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { userData, userMethodes } = React.useContext(UserLoggedInContext);

  const classes = useStyles();

  const handleUsernameChange = e => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    userMethodes
    .login(username, password, {})
    .then(_response => {
      return "OK";
    })
    .catch(error => {
      if (error.response) {
        // console.log(error.response.data.message);
        setResponse(t(error.response.data.message));
        setOpen(true);
      } else {
        console.log(error);
      }
    });
  }

  if(sessionStorage.hasOwnProperty("user")) {
    userMethodes.fromSessionStorage(JSON.parse(sessionStorage.getItem("user")));
  }

  return (
    <div className={classes.root} height="100%">
      {userData && <Redirect to="/dashboard" />}
      <Grid
        container
        spacing={0}
        justify="center"
        alignContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid item xs={12} sm={6} lg={3}>
          <Card className={classes.card}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={logo}
                title="5P SEAL BOX SYSTEM"
              />
              <CardContent>
                <Grid
                  container
                  spacing={1}
                  direction="column"
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h5" component="h2">
                      5P SEAL BOX SYSTEM - TriCode Version
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      value={username}
                      placeholder={t("user name")}
                      onChange={handleUsernameChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="password"
                      value={password}
                      placeholder={t("password")}
                      onChange={handlePasswordChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Grid
                container
                spacing={1}
                direction="column"
                justify="center"
                alignContent="center"
              >
                <Grid item xs={12}>
                  <Button size="small" variant="contained" color="primary" onClick={handleLogin}>
                    Login
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <SimpleSnackbar open={open} setOpen={setOpen} message={response} />
    </div>
  );
};

export default withRoot(Login);
