import React, { createContext, useContext } from "react";
import FormData from "form-data";
import axios from "axios";
import qs from "qs";
import { UserLoggedInContext } from "./UserLoggedIn";
import { serveur } from "../constants/API";

export const CodesContext = createContext();

export const CodesProvider = props => {
  const { userData } = useContext(UserLoggedInContext);

  async function getData(
    method,
    url,
    params = {},
    data = {}
  ) {
    const options = {
      url: `${serveur}${url}`,
      method,
      headers: {
        "x-access-token": userData.token,
        "content-type": "application/json",
        "latitude": userData.location.latitude,
        "longitude": userData.location.longitude,
        "accuracy": userData.location.accuracy
      },
      timeout: 60 * 4 * 1000,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
      params,
      data
    };
    
      let response = await axios(options);
      return response.data;
  }

  async function upload(url, data) {
    let options = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": userData.token,
        "latitude": userData.location.latitude,
        "longitude": userData.location.longitude,
        "accuracy": userData.location.accuracy
      },
      method: "PUT"
    };
    options.body = new FormData();
    for (let key in data) {
      options.body.append(key, data[key]);
    }
    return await fetch(url, options).then(response => response.json());
  }

  async function getEnrolledCodesList(params) {
    const url="codes/list";
    const options = {
      url: `${serveur}${url}`,
      method: "get",
      headers: {
        "x-access-token": userData.token,
        "content-type": "application/json",
        "latitude": userData.location.latitude,
        "longitude": userData.location.longitude,
        "accuracy": userData.location.accuracy
      },
      timeout: 60 * 4 * 1000,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
      params
    };
    
      let response = await axios(options);
      return response.data;
  }

  const codesMethodes = {
    getActivities: async function() {
      return await getData("GET", "activities");
    },
    enroll: async function(codeId, file) {
      const bodyParams = {
        codeId,
        APIKey: userData.userAPIKey,
        sello: {
          name: "sello",
          type: "image/png",
          uri: file
        }
      };

      return await upload(`${serveur}code/enroll`, bodyParams);
    },
    getEnrolledCode: async function(codeId) {
      return await getData("GET", "code/enroll", { codeId } )
    },
    getEnrolledCodeForControl: async function(codeId) {
      return await getData("GET", "code/questions", { codeId } )
    },
    postEnrolledCodeResponses: async function(codeId, responses) {
      console.log(responses);
      return await getData("POST", "code/responses", {}, { codeId, responses });
    },
    // liste paginées des codes activés triée sur le no de lot
    getEnrolledCodesList,
    // MAJ code state de Enrolled -> Valid et effacement des logs qui concernent ce code
    reinitializeCode: async function(codeId) {

    }
  };

  return (
    <CodesContext.Provider value={{ codesMethodes }}>
      {props.children}
    </CodesContext.Provider>
  );
};
