import React from "react";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

// A theme with custom primary and secondary color.
// It's optional.

const baseTheme = createMuiTheme({
  spacing: 1,
  palette: {
   primary: {
     main: "#AB0026",
     dark: "#5E0015"
   },
   secondary: {
     main: "#5E0015",
     "green": "#B8AE12"
   },
   neutral: {
     "grey": "#EAEAEA",
     "white": "#FFFFFF"
   }
  },
  typography: {
    h6: {
      fontSize: "0.9rem",
      color: "rgba(0, 0, 0, 0.6)"
    },
    useNextVariants: true
  },
  props: {
    // Name of the component
    MuiButtonBase: {
      disableRipple: true
    }
  },
});

function withRoot(Component) {
  function WithRoot(props) {
   
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={baseTheme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
