const _langue = "fr";

export const t = terme => getText(_langue, terme);

const getText = (langue, terme) =>
  langues[langue]
    ? langues[langue][terme]
      ? langues[langue][terme]
      : `terme inconnu ${terme}`
    : `langue inconnue ${langue}`;

const langues = {
  fr: {
    "Signatures do not matches": "Les QR Codes lus ne correspondent à aucun code",
    "Code state Enrolled with these 3 signatures exists": "Un code activé avec ces 3 signatures existe",
    "Code state Valid with these 3 signatures exists": "Un code valide avec ces 3 signatures existe",
    "Label number": "No Etiquette",
    "Container number": "No de Conteneur",
    "sello": "Photo des plombs",
    "username, password, email, role and APIKey are mandatory.": "Nom d'utilisateur, mot de passe et Email sont obligatoires.",
    "User has been created successfully": "L'utilisateur a été créé avec succès",
    "Admin": "Admin",
    "email": "courriel",
    "Save": "Sauvegarder",
    "Create User": "Créer un utilisateur",
    "Refresh": "Actualisation des activités",
    "GET Enrolled": "Lecture code activé",
    "From": "Du",
    "to": "au",
    "Date": "Date",
    "Code signature": "Signature du code",
    "Username": "Nom utilisateur",
    "IP address": "Adresse IP",
    "longitude": "Longitude",
    "latitude": "Latitude",
    "accuracy": "Précision (en m.)",
    "operation": "Opération",
    "result": "Résultat",
    "Réponses": "Réponses",
    "donnée": "donnée",
    "attendue": "attendue",
    "POST Responses": "Contrôle: Réponses",
    "Not a valid code": "Code invalide",
    "GET Questions": "Contrôle : Questions",
    "Label": "Etiquette",
    "Creation date": "Date de création",
    "Unable to use location service on mobile phone":
      "Impossible d'utiliser le service de localisation",
    "Permission to access location was denied":
      "La permission d'accéder au service de localisation a été refusée",
    "user name": "nom de l'utilisateur",
    password: "mot de passe",
    User: "Utilisateur",
    Customer: "Domaine",
    Seal: "Etiquette",
    Signature: "Signature",
    "Requesting for camera permission": "Demande d'accès à l'appareil photo",
    "Camera permission is not granted":
      "Impossible d'accéder à l'appareil photo",
    "Capture the seal number": "Photographiez le no de plomb",
    "Scan your QR code": "Scannez le QR Code",
    "Tap to scan again": "Reprendre le code",
    Confirm: "Confirmer",
    Cancel: "Annuler",
    Loading: "Chargement",
    Capture: "Photographier",
    "the supplied user id is not a valid one.":
      "L'Id user fourni n'est pas valide.",
    "This user is not in database.": "Cet utilisateur n'existe pas.",
    "No code found or code already enrolled !":
      "Aucun code trouvé ou code déjà enregistré !",
    "The code has been enrolled.": "Le code a été enregistré.",
    "This username and/or email address is/are already existing in database.":
      "Cet(te) utilisateur/adresse email est déjà présent(e) dans la base de données.",
    "username and/or password is/are missing.":
      "Nom d'utilisateur ou mot de passe manquant(s).",
    "Either the Username and/or Password is/are invalid.":
      "La combinaison utilisateur/mot de passe est invalide.",
      "Control": "Contrôler",
      "Enroll": "Activer",
      "Enrollment": "Activation",
      "Check": "Contrôle"
  },
  es: {
    "Signatures do not matches": "Los códigos QR leídos no coinciden con ningún código",
    "Code state Enrolled with these 3 signatures exists": "Código registrado con estas 3 firmas existe",
    "Code state Valid with these 3 signatures exists": "Código válido con estas 3 firmas existe",
    "Label number":   "Número de etiqueta",
    "Container number": "Número de contenedor",
    "sello": "Sello de seguridad",
    "username, password, email, role and APIKey are mandatory.":"nombre de usuario, contraseña, correo electrónico son obligatorios.",
    "User has been created successfully": "El usuario ha sido creado exitosamente",
    "Admin": "Admin",
    "email": "correo electrónico",
    "Save": "Registro",
    "Create User": "Crear un usuario",
    "Refresh": "Actualización de actividades",
    "GET Enrolled": "leyendo un código registrado",
    "From": "De",
    "to": "a",
    "Date": "Fecha",
    "Code signature": "Firma del código",
    "Username": "Nombre de usuario",
    "IP address": "Dirección IP",
    "longitude": "Longitud",
    "latitude": "Latitud",
    "accuracy": "Precisión (en m.)",
    "operation": "Operación",
    "result": "Resultado",
    "Réponses": "Respuestas",
    "donnée": "enviado",
    "attendue": "esperado",
    "POST Responses": "Control: Respuestas",
    "Not a valid code": "Código inválido",
    "GET Questions": "Control: Preguntas",
    "Label": "Etiqueta",
    "Creation date": "Fecha de creación",
    "Unable to use location service on mobile phone":
      "No se puede utilizar el servicio de ubicación en el teléfono móvil",
    "Permission to access location was denied":
      "El permiso para acceder a la ubicación fue denegado",
    "user name": "nombre de usuario",
    password: "contraseña",
    User: "Usuario",
    Customer: "Dominio",
    Seal: "Etiqueta",
    Signature: "Firma",
    "Requesting for camera permission": "Solicitando permiso de cámara",
    "Camera permission is not granted": "Permiso de la cámara no se concede",
    "Capture the seal number": "Fotografía el número de sello",
    "Scan your QR code": "Leer el código QR",
    "Tap to scan again": "Toque para leer de nuevo",
    Confirm: "Confirmar",
    Cancel: "Cancelar",
    Loading: "Cargando",
    Capture: "Fotografía",
    "the supplied user id is not a valid one.":
      "El ID de usuario suministrado no es válido.",
    "This user is not in database.":
      "Este usuario no está en la base de datos.",
    "No code found or code already enrolled !":
      "No se ha encontrado ningún código o código ya registrado!",
    "The code has been enrolled.": "El código ha sido registrado.",
    "This username and/or email address is/are already existing in database.":
      "Este nombre de usuario y / o correo electrónico ya existe en la base de datos",
    "username and/or password is/are missing.":
      "Falta el nombre de usuario y / o la contraseña.",
    "Either the Username and/or Password is/are invalid.":
      "El nombre de usuario y / o la contraseña no son válidos.",
      "Control": "Control",
      "Enroll": "Registrar",
      "Enrollment": "Registro",
      "Check": "Control"
  },
  en: {
    "Signatures do not matches": "Read QR Codes do not match any code",
    "Code state Enrolled with these 3 signatures exists": "Code state Enrolled with these 3 signatures exists",
    "Code state Valid with these 3 signatures exists": "Code state Valid with these 3 signatures exists",
    "Label number": "Label Number",
    "Container number": "Container Number",
    "sello": "Photo of the seal",
    "username, password, email, role and APIKey are mandatory.": "username, password, email are mandatory.",
    "User has been created successfully": "User has been successfully created",
    "Admin": "Admin",
    "email": "email",
    "Save": "Save",
    "Create User": "Create a user",
    "Refresh": "Activities update",
    "GET Enrolled": "Enrolled code read",
    "From": "From",
    "to": "to",
    "Date": "Date",
    "Code signature": "Code signature",
    "Username": "User name",
    "IP address": "IP address",
    "longitude": "Longitude",
    "latitude": "Latitude",
    "accuracy": "Accuracy (in m.)",
    "operation": "Operation",
    "result": "Result",
    "Réponses": "Answers",
    "donnée": "given",
    "attendue": "expected",
    "POST Responses": "Control : Answers",
    "Not a valid code": "Not a valid code",
    "GET Questions": "Control : Questions",
    "Label": "Label",
    "Creation date": "Creation date",
    "Unable to use location service on mobile phone":
      "Unable to use location service on mobile phone",
    "Permission to access location was denied":
      "Permission to access location was denied",
    "user name": "user name",
    password: "password",
    User: "User",
    Customer: "Domain",
    Seal: "Label",
    Signature: "Signature",
    "Requesting for camera permission": "Requesting for camera permission",
    "Camera permission is not granted": "Camera permission is not granted",
    "Capture the seal number": "Capture the seal number",
    "Scan your QR code": "Scan the QR code",
    "Tap to scan again": "Tap to scan again",
    Confirm: "Confirm",
    Cancel: "Cancel",
    Loading: "Loading",
    Capture: "Capture",
    "the supplied user id is not a valid one.":
      "the supplied user id is not a valid one.",
    "This user is not in database.": "This user is not in database.",
    "No code found or code already enrolled !":
      "No code found or code already enrolled !",
    "The code has been enrolled.": "The code has been enrolled.",
    "This username and/or email address is/are already existing in database.":
      "This username and/or email address is/are already existing in database.",
    "username and/or password is/are missing.":
      "username and/or password is/are missing.",
    "Either the Username and/or Password is/are invalid.":
      "Either the Username and/or Password is/are invalid.",
      "Control": "Control",
      "Enroll": "Enroll",
      "Enrollment": "Enrollment",
      "Check": "Control"
  }
};
