import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import withRoot from "../../theme/withRoot";
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { UserLoggedInContext } from "DataStore/UserLoggedIn";
import SimpleSnackbar from "components/Snackbar";
import { t } from "DataStore/langues";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  card: {
    maxWidth: "100%"
  }
}));

const UserForm = props => {
  const { title, userGUID, openDialog, setOpenDialog } = props;
  const { userData, userMethodes } = React.useContext(UserLoggedInContext);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("User");
  const [response, setResponse] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const classes = useStyles();

  const handleChange = (event, set) => {
    set(event.target.value);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleSave = () => {
      setLoading(true);
    if (userGUID === 0) {
      userMethodes
        .createUser({
          username,
          email,
          password,
          role,
          APIKey: userData.user.userAPIKey
        })
        .then(_retour => {
            if(_retour.message) return setResponse(t(_retour.message));
            setResponse(t("User has been created successfully"));
            setUsername("");
            setPassword("");
            setEmail("");
            setRole("User");
        })
      setOpen(true);
      setLoading(false);
    } else {
      console.log("non implémenté");
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t(title)}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            value={username}
            placeholder={t("user name")}
            fullWidth
            onChange={e => handleChange(e, setUsername)}
          />
          <TextField
            margin="dense"
            type="password"
            value={password}
            placeholder={t("password")}
            fullWidth
            onChange={e => handleChange(e, setPassword)}
          />

          <TextField
            margin="dense"
            type="email"
            value={email}
            placeholder={t("email")}
            fullWidth
            onChange={e => handleChange(e, setEmail)}
          />

          <FormControl className={classes.formControl}>
            <Select
              disabled={userData && userData.user.userRole !== "Admin"}
              value={role}
              onChange={e => handleChange(e, setRole)}
              name="role"
              fullWidth
              className={classes.selectEmpty}
            >
              <MenuItem value="User">{t("User")}</MenuItem>
              <MenuItem value="Admin">{t("Admin")}</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleSave} color="primary">
          {loading ?
            <CircularProgress size={14} /> : 
            t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
      <SimpleSnackbar open={open} setOpen={setOpen} message={response} />
    </div>
  );
};

export default withRoot(UserForm);
