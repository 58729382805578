import React from "react";
import { Redirect } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import withRoot from "../../theme/withRoot";
import { t } from "DataStore/langues";
import SimpleSnackbar from "components/Snackbar";
import { UserLoggedInContext } from "DataStore/UserLoggedIn";
import { CodesContext } from "DataStore/Codes";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Grid from "@material-ui/core/Grid";
import { TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/es";
import Trie from "DataStore/Trie";
import logo from "assets/images/logo.png";
import UserForm from "components/User/UserForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import { serveur } from "constants/API";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: "100%",
    backgroundColor: theme.palette.primary.dark,
    overflowX: "scroll"
  },
  table: {
    minWidth: 500,
    backgroundColor: "white"
  },
  tableWrapper: {
    // overflowX: "auto"
  },
  searchField: {
    width: "90px"
  },
  dateLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },
  form: {
    minWidth: 1000,
    overflowX: "hidden",
    backgroundColor: "white",
    paddingTop: 20,
    paddingBottom: 20
  },
  bandeau: {
    minWidth: 1000,
    backgroundColor: theme.palette.primary.main,
    overflowX: "hidden"
  },
  sello: {
    width: 200,
    heigth: "auto"
  }
}));

const Dashboard = props => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { userData } = React.useContext(UserLoggedInContext);
  const { codesMethodes } = React.useContext(CodesContext);
  const [activities, setActivities] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [dateDeb, setDateDeb] = React.useState(moment().startOf("year"));
  const [dateFin, setDateFin] = React.useState(moment().endOf("year"));
  const [username, setUsername] = React.useState("");
  const [conteneur, setConteneur] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [trieUsername, setTrieUsername] = React.useState(new Trie());
  const [trieConteneur, setTrieConteneur] = React.useState(new Trie());

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

  const updateActivities = () => {
    if (userData) {
      setLoading(true);
      codesMethodes
        .getActivities()
        .then(_response => {
          setActivities(_response);
          _response.map(activity => {
            trieUsername.add(activity.user.userName.toUpperCase());
            trieConteneur.add(activity.code.container);
            return 0;
          });
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data.message);
            setResponse(error.response.data.message);
          } else {
            console.log(error);
          }
        });
      setLoading(false);
    }
  };

  moment.locale("es");

  React.useEffect(updateActivities, []);

  React.useEffect(() => {
    setPage(0);
  }, [username, conteneur])

  const handleChangeUsername = event => {
    setUsername(event.target.value);
  };

  const handleChangeConteneur = event => {
    setConteneur(event.target.value);
  };

  let displayedActivities = [];

  if (activities) {
    let filteredByUsername = [];
    if (username === "" && conteneur === "") {
      filteredByUsername = activities;
    } else {
      let foundUsernames = trieUsername.findWords(username.toUpperCase());
      filteredByUsername = activities.filter(activity =>
        foundUsernames.includes(activity.user.userName.toUpperCase())
      );
      let foundConteneurs = trieConteneur.findWords(conteneur.toUpperCase());
      filteredByUsername = filteredByUsername.filter(activity =>
        foundConteneurs.includes(activity.code.container.toUpperCase())
      );
    }
    displayedActivities = filteredByUsername.filter(activity =>
      moment(activity.dateCreation).isBetween(dateDeb, dateFin)
    );
  }
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, displayedActivities.length - page * rowsPerPage);

  const handleUserForm = () => {
    setOpenDialog(true);
  };

  const handleRefresh = () => {
    updateActivities();
  };

  return (
    <Paper className={classes.root}>
      {userData === null && <Redirect to="/login" />}
      <Grid
        container
        spacing={1}
        className={classes.bandeau}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={3}>
          <img src={logo} height="100px" width="auto" alt="5D Secure Systems" />
        </Grid>
        {userData !== null && userData.user.userRole === "Admin" && (
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUserForm}
            >
              {t("Create User")}
            </Button>
          </Grid>
        )}
        <Grid item xs={3}>
          <Button variant="contained" color="secondary" onClick={handleRefresh}>
            {loading ? <CircularProgress size={14} /> : t("Refresh")}
          </Button>
        </Grid>
      </Grid>
      {activities ? (
        <Grid container direction="column" spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1} className={classes.form}>
              <Grid item xs={2}>
                <Typography variant="h5" style={{paddingLeft: 20}}>Búsqueda</Typography>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale="es"
                  libInstance={moment}
                >
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={11} sm={4}>
                      <div className={classes.dateLine}>
                        <Typography variant="h6">{t("From")} </Typography>
                        <DatePicker
                          className={classes.searchField}
                          value={dateDeb}
                          onChange={setDateDeb}
                          format="DD-MM-YYYY"
                        />
                      </div>
                    </Grid>

                    <Grid item xs={11} sm={4}>
                      <div className={classes.dateLine}>
                        <Typography variant="h6"> {t("to")} </Typography>
                        <DatePicker
                          className={classes.searchField}
                          value={dateFin}
                          onChange={setDateFin}
                          format="DD-MM-YYYY"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={username}
                  placeholder={t("user name")}
                  onChange={handleChangeUsername}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={conteneur}
                  placeholder={t("Container number")}
                  onChange={handleChangeConteneur}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Date")}</TableCell>
                    <TableCell>
                      {t("Label number")}
                    </TableCell>
                    <TableCell>
                      {t("Container number")} <br /> {t("sello")}
                    </TableCell>
                    <TableCell>{t("Username")}</TableCell>
                    <TableCell>
                      {t("IP address")} <br /> ({t("latitude")},{t("longitude")}
                      ,{t("accuracy")})
                    </TableCell>
                    <TableCell>{t("operation")}</TableCell>
                    <TableCell>{t("result")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedActivities
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(row => (
                      <TableRow
                        key={row._id}
                        style={{
                          backgroundColor:
                            row.result.substring(0, 2) === "OK"
                              ? "lightgreen"
                              : "red"
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {moment(row.dateCreation).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                        </TableCell>
                        <TableCell>
                          {row.codeId}
                        </TableCell>
                        <TableCell>
                          {row.code.container}{" "}
                          {row.code.sello && <img
                            src={`${serveur}${row.code.sello}`}
                            className={classes.sello}
                            alt={row.codeId}
                          />}
                        </TableCell>
                        <TableCell>{row.user.userName}</TableCell>
                        <TableCell>
                          {row.location.user.ip} <br /> (
                          {Number.parseFloat(
                            row.location.user.latitude
                          ).toFixed(3)}
                          ,{" "}
                          {Number.parseFloat(
                            row.location.user.longitude
                          ).toFixed(3)}
                          ,{" "}
                          {Number.parseFloat(
                            row.location.user.accuracy
                          ).toFixed(2)}
                          )
                        </TableCell>
                        <TableCell>{t(row.operation)}</TableCell>
                        <TableCell>{row.result}</TableCell>
                      </TableRow>
                    ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 48 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={3}
                      count={displayedActivities.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "filas por página" },
                        native: true
                      }}
                      labelRowsPerPage="filas por página :"
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h5" style={{ color: "white" }}>
          Hay no actividades
        </Typography>
      )}
      <UserForm
        title="Create User"
        userGUID={0}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
      <SimpleSnackbar open={open} setOpen={setOpen} message={response} />
    </Paper>
  );
};

export default withRoot(Dashboard);

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
