import React, { useState, createContext } from "react";
import axios from "axios";
import { serveur } from "../constants/API";

export const UserLoggedInContext = createContext();

export const UserLoggedInProvider = props => {
  const [userData, setUserData] = useState(null);

  async function getData(username, password, location) {
    const options = {
      timeout: 60 * 4 * 1000
    };

    let response = await axios.post(
      `${serveur}user/login`,
      {
        username: username,
        password: password
      },
      options
    );
    const _userData = response.data;
    if (location.location) {
      // console.log(location.location.coords);
      _userData.location = location.location.coords;
    } else {
      _userData.location = { latitude: 0, longitude: 0, accuracy: -1 };
    }
    sessionStorage.setItem("user", JSON.stringify(_userData));
    setUserData(_userData);
    return _userData;
  }

  async function post(url, data) {
    const options = {
      timeout: 60 * 4 * 1000,
      headers: {
        "x-access-token": userData.token,
        "content-type": "application/json"
      },
      validateStatus: (status) => {
        return true; // I'm always returning true, you may want to do it depending on the status received
      },
    };

    let response = await axios.post(`${serveur}${url}`, data, options);
    return response.data;
  }

  const userMethodes = {
    login: async function(username, password, location) {
      return await getData(username, password, location);
    },
    logout: async function() {
      sessionStorage.removeItem("user");
      setUserData(null);
      return null;
    },
    fromSessionStorage: async function(_user) {
      await setUserData(_user);
    },
    createUser: async function(_user) {
      return await post("user/register", _user);
    },
    updateUser: async function(_user) {
      return await post("user/update", _user);
    }
  };

  return (
    <UserLoggedInContext.Provider value={{ userData, userMethodes }}>
      {props.children}
    </UserLoggedInContext.Provider>
  );
};
